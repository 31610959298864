import {ecomAppDefID, wishlistPageId} from '../constants';
import {DependantApps} from './dependantApps';

export async function reinstallWishlistMigration(sdk: IEditorSdk, appToken: string, dependantApps: DependantApps) {
  const pagesData = await sdk.pages.data.getAll();
  const wishlistPage = pagesData.find(page => page.tpaPageId === wishlistPageId);
  if (!wishlistPage) {
    return;
  }

  const wishlistPageRef = {id: wishlistPage.id, type: wishlistPage.type};
  await sdk.pages.data.update(appToken, {
    pageRef: wishlistPageRef,
    data: {managingAppDefId: ecomAppDefID},
  });
  await sdk.pages.remove(appToken, {pageRef: wishlistPageRef, shouldShowEditorRemovePanel: false});
  await dependantApps.installWishlistPageInMembersArea();
}
